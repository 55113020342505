<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['การเงิน', 'Internal Transfer']" />
    <div class="mb-3" v-if="bankVersion === 1">
      <button class="btn btn-danger mr-2" type="button" @click="getBalance('SCB')">
        เงินคงเหลือ scb
      </button>
      <button class="btn btn-success" type="button" @click="getBalance('KBANK')">
        เงินคงเหลือ kbank
      </button>
    </div>
    <div v-if="canViewBalance || canEdit">
      <div class="text-info h3 mb-0">ธนาคารต้นทาง</div>
      <div class="form-group">
        <label for="bankCode">ธนาคาร</label>
        <select v-if="bankVersion === 1" class="form-control w-auto" id="bankCode" v-model="form.bankCode"
          :disabled="!canEdit">
          <option value="" disabled>กรุณาเลือกธนาคาร</option>
          <option value="KBANK">KBANK ธ.กสิกรไทย</option>
          <option value="SCB">SCB ธ.ไทยพาณิชย์</option>
        </select>
        <select v-if="bankVersion == 2" class="form form-control w-auto mb-2" id="bankCode" v-model="form.platformBankID">
          <option v-for="(item, index) in platformBanks" :key="index" :value="item.id">
            {{
              `#${item.id} ${item.bankCode} ${item.accountName} ${item.accountNumber}`
            }}
          </option>
        </select>
        <button v-if="bankVersion === 2" @click="getBalance()" class="btn btn-primary" v-show="canViewBalance || canEdit">
          เงินคงเหลือ
        </button>
      </div>
    </div>
    <ValidationObserver ref="observer" tag="form" @submit.prevent="transferMoney" novalidate>
      <div v-if="canEdit">
        <div class="form-group">
          <ValidationProvider :rules="`required|numeric`" v-slot="v">
            <label for="amount">จำนวนเงิน</label>
            <input type="text" class="form-control w-auto" id="amount" v-model="form.amount" placeholder="0"
              :readonly="!canEdit" />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="text-info h3 mt-4 mb-0">ธนาคารปลายทาง</div>
        <div class="form-group">
          <label>ธนาคาร</label>
          <ValidationProvider rules="required" v-slot="v">
            <select class="form-control w-auto mb-1" id="toBankCode" v-model="form.toBankCode" :disabled="!canEdit">
              <option value="" disabled>กรุณาเลือกธนาคาร</option>
              <option value="BBL">BBL ธ.กรุงเทพ</option>
              <option value="KBANK">KBANK ธ.กสิกรไทย</option>
              <option value="KTB">KTB ธ.กรุงไทย</option>
              <option value="SCB">SCB ธ.ไทยพาณิชย์</option>
              <option value="BAY">BAY ธ.กรุงศรีอยุธยา</option>
              <option value="CIMB">CIMB ธ.ซีไอเอ็มบี</option>
              <option value="GSB">GSB ธ.ออมสิน</option>
              <option value="KK">KIATNAKIN ธ.เกียรตินาคิน</option>
              <option value="LHB">LHBANK ธ.แลนด์ แอนด์ เฮ้าส์</option>
              <option value="SC">STANDARD ธ.สแตนดาร์ดชาร์เตอร์ด (ไทย)</option>
              <option value="TISCO">TISCO ธ.ทิสโก้</option>
              <option value="TTB">TTB ธ.ทหารไทยธนชาต</option>
              <option value="UOB">UOB ธ.ยูโอบี</option>
              <option value="BAAC">BAAC ธกส</option>
            </select>
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>ชื่อบัญชี</label>
          <ValidationProvider rules="required" v-slot="v">
            <input type="text" class="form-control w-auto mb-1" v-model="form.toAccountName" placeholder="นายxx xx"
              :readonly="!canEdit" autocomplete />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>เลขบัญชี</label>
          <ValidationProvider rules="required|min:10|numeric" v-slot="v">
            <input type="text" class="form-control w-auto mb-1" v-model="form.toAccountNumber" placeholder="1234567890"
              :readonly="!canEdit" autocomplete />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <button class="btn btn-primary mt-3" type="submit" v-show="canEdit">ตกลง</button>
      </div>

    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        bankCode: "SCB",
        toBankCode: "",
        platformBankID: 0,
      },
      bankVersion: 0,
      platformBanks: [],
    }
  },
  methods: {
    async getBalance(bankCode) {
      this.swal.processing()

      try {
        if (this.bankVersion === 2) {
          bankCode = this.platformBanks.find(
            (item) => item.id === this.form.platformBankID
          ).bankCode
        }

        const res = await this.axios({
          method: "get",
          url: "bank/balance",
          params: {
            bankCode: bankCode,
            platformBankID: this.form.platformBankID,
          },
        })

        this.swal.success(res.data.data)
      } catch (e) {
        this.swal.catchError(e)
      }
    },
    async transferMoney() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      let fromBankMsg = `ธนาคาร: ${this.form.bankCode}`
      if (this.bankVersion === 2) {
        const item = this.platformBanks.find(
          (item) => item.id === this.form.platformBankID
        )
        fromBankMsg = `ธนาคาร: ${item.bankCode}<br>ชื่อบัญชี: ${item.accountName}<br>เลขบัญชี: ${item.accountNumber}`
        this.form.bankCode = item.bankCode
      }

      let { isConfirmed } = await this.$swal.fire({
        title: "ท่านต้องการโยกเงินใช่หรือไม่",
        html: `
<b>บัญชีต้นทาง</b><br>
${fromBankMsg}<br>
<b>จำนวนเงิน: ฿ ${this.util.formatMoney(this.form.amount)}</b><br><br>

<b>บัญชีปลายทาง</b><br>
ธนาคาร: ${this.form.toBankCode}<br>
ชื่อบัญชี: ${this.form.toAccountName}<br>
เลขบัญชี: ${this.form.toAccountNumber}<br><br>
`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      })

      if (!isConfirmed) {
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "bank/reserve",
          data: this.form,
        })

        await this.swal.success()

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        this.swal.catchError(e)
      }
    },
  },
  async mounted() {
    this.swal.processing()

    try {
      const res = await this.axios({
        method: "get",
        url: "bank/reserve",
      })
      const data = res.data.data

      this.bankVersion = data.bankVersion
      this.platformBanks = data.platformBanks

      this.swal.close()
    } catch (e) {
      this.swal.catchError(e)
    }
  },
  computed: {
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes("transfer_money_edit")
    },
    canViewBalance() {
      return this.$store.state.auth.user?.userData?.permissions?.includes("transfer_money_balance_view")
    },
  }
}
</script>